import React from "react";
import ToSContent from "../views/ToSContent";
import { MetaDataHead } from "../components";
import { useRedirectByLang } from "../hooks";

const ToSPage = () => {
  useRedirectByLang();
  return (
    <>
      <MetaDataHead>
        <link rel="preload" href={`${process.env.GATSBY_ROOT_PATH}/bg-01.jpg`} as="image" />
      </MetaDataHead>
      <ToSContent />
    </>
  );
};

export default ToSPage;
